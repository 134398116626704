import { fetchNotifications } from "../../../services/notificationService";

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const IS_LOADING = "IS_LOADING";
export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";
export const INCREASE_NOTIFICATION_COUNT = "INCREASE_NOTIFICATION_COUNT";

export const GetNotificationsAction = (dispatch, setUnreadCount = false) => {
  return async () => {
    dispatch(SetLoadingAction(true));

    const data = new FormData();
    data.append("user_id", window.localStorage.getItem("user_id"));

    try {
      const response = await fetchNotifications(data);

      if (response.data.statusCode === 200) {
        const notifications = response.data.data;
        dispatch(SetNotificationsAction(notifications));
        if (setUnreadCount) {
          dispatch(
            SetNotificationCountAction(
              notifications.filter((item) => item.flag_read === 0).length
            )
          );
        }
      }
    } catch (err) {
      console.log(err);
    }

    dispatch(SetLoadingAction(false));
  };
};

const SetNotificationsAction = (value) => {
  return { type: SET_NOTIFICATIONS, value };
};

const SetLoadingAction = (value) => {
  return {
    type: IS_LOADING,
    value,
  };
};

export const SetNotificationCountAction = (value) => {
  return {
    type: SET_NOTIFICATION_COUNT,
    value,
  };
};

export const IncreaseNotificationCountAction = () => {
  return {
    type: INCREASE_NOTIFICATION_COUNT,
  };
};
