import ServiceClient from "./serviceClientConfig";

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_APIS,
});

export const getAuthenticatedMuxUrl = () => {
  return serviceClient.post("mux/mux-url");
};

export const fetchLiveUsers = () => {
  return serviceClient.get("mux/live-stream/active-users");
};

export const toggleLiveStreamHeartbeat = (payload) => {
  return serviceClient.post("mux/toggle-live-stream-heartbeat", payload);
};

export const updateMuxAssets = () => {
  return serviceClient.post("mux/update-assets");
};

export const fetchSignedMuxJwt = (payload) => {
  return serviceClient.post("mux/mux-signIn-keys", payload);
};

export const startSpaceBroadcast = (payload) => {
  return serviceClient.post("mux/start/broadcasting", payload);
};

export const stopSpaceBroadcast = (payload) => {
  return serviceClient.post("mux/stop/broadcasting", payload);
};

export const fetchLiveStreamDetails = (payload) => {
  return serviceClient.post(
    "mux/get_live_streamer_details_by_playback_id",
    payload
  );
};

export const updateViewCount = (payload) => {
  return serviceClient.post("mux/views", payload);
};

export const tipLiveStream = (payload) => {
  return serviceClient.post("mux/tipamount", payload);
};

export const uploadToMuxByUrl = (payload) => {
  return serviceClient.post("mux/send_video_by_url", payload);
};
