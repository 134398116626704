import React, { useEffect, useMemo, useState } from "react";
import {
  liveStreamingMessageTypes,
  pubnubPublishKey,
  pubnubSubscribeKey,
} from "../../utils/utils";
import Pubnub from "pubnub";
import { connect } from "react-redux";

export const PubnubContext = React.createContext();

const PubnubProvider = ({ user, isUserLive, children }) => {
  const [liveStreamLikeCount, setLiveStreamLikeCount] = useState(0);
  const [broadcastId, setBroadcastId] = useState(null);
  const [pubnub, setPubNub] = useState(null);

  const listener = useMemo(() => {
    return {
      signal: (signal) => {
        if (signal.message === `${liveStreamingMessageTypes.like}.${user.id}`) {
          setLiveStreamLikeCount((prevState) => prevState + 1);
        }
      },
    };
  }, [user.id]);

  useEffect(() => {
    const pubnub = new Pubnub({
      publishKey: pubnubPublishKey,
      subscribeKey: pubnubSubscribeKey,
      uuid: window.localStorage.getItem("user_id"),
    });
    pubnub.addListener(listener);
    setPubNub(pubnub);

    return () => {
      pubnub.removeListener(listener);
      pubnub.unsubscribeAll();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isUserLive) {
      setLiveStreamLikeCount(0);
      setBroadcastId(null);
    }

    // eslint-disable-next-line
  }, [isUserLive]);

  useEffect(() => {
    if (!broadcastId) {
      return;
    }
    const setMetadataTimeout = setTimeout(() => {
      setChannelMetadata();
    }, 300);

    return () => {
      if (setMetadataTimeout) {
        clearTimeout(setMetadataTimeout);
      }
    };
    // eslint-disable-next-line
  }, [liveStreamLikeCount, broadcastId]);

  const setChannelMetadata = () => {
    try {
      pubnub.objects.setChannelMetadata({
        channel: `Live${broadcastId}`,
        data: {
          name: "Live channel",
          description: "Stores metadata about the live stream",
          custom: { likeCount: liveStreamLikeCount },
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <PubnubContext.Provider
      value={{
        pubnub,
        liveStreamLikeCount,
        setLiveStreamLikeCount,
        setBroadcastId,
      }}
    >
      {children}
    </PubnubContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.app.user,
    isUserLive: state.live.isUserLive,
  };
};
export default connect(mapStateToProps)(PubnubProvider);
