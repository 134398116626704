import {
  SET_IS_CAMERA_MUTED,
  SET_IS_MICROPHONE_MUTED,
  SET_MICROPHONE_DEVICE_ID,
  SET_IS_USING_MICROPHONE,
  SET_ACTIVE_MUSIC_TRACK,
} from "../../actions/mediaDevice/mediaDeviceActions";

export const mediaDeviceInitialState = {
  isCameraMuted: false,
  isMicrophoneMuted: false,
  microphoneDeviceId: "default",
  isUsingMicrophone: true,
  activeMusicTrack: null,
};

const mediaDevice = (state = mediaDeviceInitialState, action) => {
  switch (action.type) {
    case SET_IS_CAMERA_MUTED:
      return {
        ...state,
        isCameraMuted: action.value,
      };
    case SET_IS_MICROPHONE_MUTED:
      return {
        ...state,
        isMicrophoneMuted: action.value,
      };
    case SET_MICROPHONE_DEVICE_ID:
      return {
        ...state,
        microphoneDeviceId: action.value,
      };
    case SET_IS_USING_MICROPHONE:
      return {
        ...state,
        isUsingMicrophone: action.value,
      };
    case SET_ACTIVE_MUSIC_TRACK:
      return {
        ...state,
        activeMusicTrack: action.value,
      };
    default:
      return state;
  }
};
export default mediaDevice;
