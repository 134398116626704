import { fetchMyUsers } from "../../../services/userService";

export const ONLINE_CHANNELS = "ONLINE_CHANNELS";
export const SET_MESSAGE_COUNT = "SET_MESSAGE_COUNT";
export const APPEND_MESSAGE_COUNT = "APPEND_MESSAGE_COUNT";
export const CHAT_USERS_IDS = "CHAT_USERS_IDS";
export const ALL_USERS = "ALL_USERS";
export const FOLLOWERS = "FOLLOWERS";
export const FOLLOWING = "FOLLOWING";
export const SUBSCRIBERS = "SUBSCRIBERS";
export const SUBSCRIBING = "SUBSCRIBING";
export const IS_FETCHING_USERS = "IS_FETCHING_USERS";

export const ChatUsersAction = (dispatch, showFetching = true) => {
  return async () => {
    if (showFetching) {
      dispatch(SetIsFetchingUsers(true));
    }

    try {
      const response = await fetchMyUsers();
      if (response.data.statusCode === 200) {
        /* TO DO
            Filter unique users on the server
            This is a temporary solution
          */
        const uniqueIds = [];
        const allUsers = [];
        const uniqueFollowers = [];
        const allFollowers = [];
        const uniqueFollowings = [];
        const allFollowings = [];
        const uniqueSubscribers = [];
        const allSubscribers = [];
        const uniqueSubscribing = [];
        const allSubscribing = [];

        response.data.all.forEach((user) => {
          if (!uniqueIds.includes(user.user?.id)) {
            uniqueIds.push(user.user?.id);
            allUsers.push(user);
          }
        });

        response.data.follower.forEach((user) => {
          if (!uniqueFollowers.includes(user.user?.id)) {
            uniqueFollowers.push(user.user?.id);
            allFollowers.push(user);
          }
        });

        response.data.followings.forEach((user) => {
          if (!uniqueFollowings.includes(user.user?.id)) {
            uniqueFollowings.push(user.user?.id);
            allFollowings.push(user);
          }
        });

        response.data.subscriber.forEach((user) => {
          if (!uniqueSubscribers.includes(user.user?.id)) {
            uniqueSubscribers.push(user.user?.id);
            allSubscribers.push(user);
          }
        });

        response.data.subscribe.forEach((user) => {
          if (!uniqueSubscribing.includes(user.user?.id)) {
            uniqueSubscribing.push(user.user?.id);
            allSubscribing.push(user);
          }
        });

        dispatch(SetChatUsersIdsAction(uniqueIds));
        dispatch(SetAllUsersAction(allUsers));
        dispatch(SetFollowersAction(allFollowers));
        dispatch(SetFollowingAction(allFollowings));
        dispatch(SetSubscribersAction(allSubscribers));
        dispatch(SetSubscribingAction(allSubscribing));
        if (showFetching) {
          dispatch(SetIsFetchingUsers(false));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const SetChatUsersIdsAction = (value) => {
  return { type: CHAT_USERS_IDS, value };
};

export const SetAllUsersAction = (value) => {
  return { type: ALL_USERS, value };
};

export const SetFollowersAction = (value) => {
  return { type: FOLLOWERS, value };
};

export const SetFollowingAction = (value) => {
  return { type: FOLLOWING, value };
};

export const SetSubscribersAction = (value) => {
  return { type: SUBSCRIBERS, value };
};

export const SetSubscribingAction = (value) => {
  return { type: SUBSCRIBING, value };
};

export const SetIsFetchingUsers = (value) => {
  return { type: IS_FETCHING_USERS, value };
};
