import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import GoldenArrow from "../../assets/img/golden-check-mark.png";
import IconClose from "../../assets/img/icon-cross.svg";
import { useHistory } from "react-router-dom";

const VerificationModal = ({ isModalOpen, closeModal, text = null }) => {
  let history = useHistory();
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={closeModal}
      className="modal-dialog-centered model-suggestion-modal get-verified-modal"
    >
      <div className="modal-close-btn" onClick={closeModal}>
        <img src={IconClose} alt="close-icon" />
      </div>
      <ModalBody>
        <div className="modal-content-holder">
          <div className="modal-title">
            <figure>
              <img src={GoldenArrow} alt="/" />
            </figure>
            <p className="title">
              <strong>GET VERIFIED NOW!!!</strong>
            </p>
            {text ? (
              <p className="sub-title">{text}</p>
            ) : (
              <p className="sub-title">
                *Get a gold verified check by your name and profile for
                submitting your ID
              </p>
            )}
          </div>
          <div className="text-center mb-2">
            <Button.Ripple
              style={{
                border: "none",
              }}
              size="lg"
              color="primary"
              onClick={() => {
                history.push("/Settings/#/verification");
                closeModal();
              }}
            >
              Get Verified
            </Button.Ripple>
          </div>

          {/* <div className="upload-notes">
        <p>
          *To make purchases, verify your account. Go to “Settings” to
          verify.
        </p>
      </div> */}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default VerificationModal;
