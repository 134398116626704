import axios from "axios";
import { beforeUnloadHandler } from "../utils/beforeUnloadHandler";

export default class ServiceClient {
  client;
  constructor(config) {
    this.client = axios.create(config);

    this.client.interceptors.request.use(
      async (config) => {
        let accessToken = getAccessToken();

        if (!!accessToken) {
          if (config.headers) {
            config.headers["Authorization"] = "Bearer " + accessToken;
          }
          if (!config.headers) {
            config.headers = {};
            config.headers["Authorization"] = "Bearer " + accessToken;
          }
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalConfig = error?.config;
        const isLogin = String(originalConfig?.url).endsWith("login");

        if (
          !isLogin &&
          (error?.response?.status === 401 ||
            error?.response?.status === 403) &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;
          await new Promise((resolve) => setTimeout(resolve, 1000));
          return this.client(originalConfig);
        } else if (
          !isLogin &&
          (error?.response?.status === 401 ||
            error?.response?.status === 403) &&
          originalConfig._retry
        ) {
          clearData();
          window.removeEventListener("beforeunload", beforeUnloadHandler);
          window.location.reload();
        }

        return Promise.reject(error);
      }
    );
  }

  get(endpoint, config = {}) {
    return this.client.get(endpoint, config);
  }

  post(endpoint, payload, config) {
    return this.client.post(endpoint, payload, config);
  }

  put(endpoint, payload) {
    return this.client.put(endpoint, payload);
  }

  delete(endpoint, payload) {
    return this.client.delete(endpoint, { data: payload });
  }

  patch(endpoint, payload) {
    return this.client.patch(endpoint, payload);
  }
}

const getAccessToken = () => {
  return window.localStorage.getItem("token");
};

const clearData = () => {
  window.localStorage.clear();
};
