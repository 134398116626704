import { combineReducers } from "redux";
import customizer from "./customizer/customizer.js";
import app from "./app/appReducer";
import messaging from "./messaging/messagingReducer.js";
import notifications from "./notifications/notificationsReducer.js";
import live from "./liveReducer/liveReducer.js";
import mediaDevice from "./mediaDevice/mediaDeviceReducer.js";

const rootReducer = combineReducers({
  customizer: customizer,
  app: app,
  messaging: messaging,
  notifications: notifications,
  live: live,
  mediaDevice: mediaDevice,
});

export default rootReducer;
