import React from "react";
import { PubnubContext } from "./PubnubProvider";

function withPubnub(WrappedComponent) {
  return function (props) {
    return (
      <PubnubContext.Consumer>
        {(value) => <WrappedComponent {...props} {...value} />}
      </PubnubContext.Consumer>
    );
  };
}

export default withPubnub;
