import ServiceClient from "./serviceClientConfig";

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_APIS,
});

export const followUser = (payload) => {
  return serviceClient.post("user/follow", payload);
};

export const fetchSubscription = (payload) => {
  return serviceClient.post("user/hasSubscription", payload);
};

export const fetchMyUsers = () => {
  return serviceClient.get("user/user_all");
};

export const fetchUsers = (payload) => {
  return serviceClient.post("auth/getUsersWithSearch", payload);
};

export const tipUser = (payload, config) => {
  return serviceClient.post("user/tip", payload, config);
};

export const blockUser = (payload) => {
  return serviceClient.post("user/block", payload);
};

export const fetchBlockedUsers = () => {
  return serviceClient.get("user/block/list");
};

export const fetchFollowing = () => {
  return serviceClient.post("user/follower-n-following/following");
};

export const fetchFollowers = () => {
  return serviceClient.post("user/follower-n-following/follower");
};

export const getUserInterrelation = (payload) => {
  return serviceClient.get(`user/interrelation/${payload.id}`);
};

export const verifyAgeAndAgreement = (payload) => {
  return serviceClient.post("user/agreement", payload);
};

export const changeBankPopupSeenStatus = (payload) => {
  return serviceClient.post("auth/change-bank-popup-flag-status", payload);
};

export const fetchSubscriptions = (payload) => {
  return serviceClient.get(`user/my-subscription/${payload.type}`);
};

export const fetchPlatformModels = (payload) => {
  return serviceClient.get(`front-models?page_no=${payload.page}`);
};

export const subscribeUser = (payload) => {
  return serviceClient.post("user/subscribe", payload);
};

export const unsubscribeUser = (payload) => {
  return serviceClient.post("user/unsubscribe", payload);
};

export const fetchSuggestedUsers = () => {
  return serviceClient.post("auth/suggested");
};

export const fetchSubscribers = () => {
  return serviceClient.get("my-subscriber");
};

export const toggleSensitiveContent = (config) => {
  return serviceClient.post("user/content/control", null, config);
};

export const documentVerification = (payload) => {
  return serviceClient.post("auth/verify/document", payload);
};
