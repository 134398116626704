import {
  IS_LOADING,
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_COUNT,
  INCREASE_NOTIFICATION_COUNT,
} from "../../actions/notifications/notificationsActions";

export const notificationsInitialState = {
  notifications: [],
  isLoading: false,
  notificationCount: 0,
};

const notifications = (state = notificationsInitialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.value,
      };
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.value,
      };
    case SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.value,
      };
    case INCREASE_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: state.notificationCount + 1,
      };
    default:
      return state;
  }
};

export default notifications;
