import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Layout } from "./components/containers/Layout";
// import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import { IKContext } from "imagekitio-react";
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { MessagingContext } from "./utils/messagingContext";
import "./index.scss";

const LazyApp = lazy(() => import("./App"));

const urlEndpoint = process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

let messaging = null;
if (navigator?.serviceWorker) {
  navigator?.serviceWorker?.register("./firebase-messaging-ff-uat-sw.js");
  const app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
}

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <MessagingContext.Provider value={messaging}>
        <IKContext urlEndpoint={urlEndpoint}>
          <Layout>
            <LazyApp />
          </Layout>
        </IKContext>
      </MessagingContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
