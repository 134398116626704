import {
  SetIsUserLiveAction,
  SetIsUserLiveFromThisSessionAction,
} from "../liveActions/liveActions";
import { getUser } from "../../../services/authService";
import { fetchSuggestedUsers, fetchUsers } from "../../../services/userService";
import { checkFcmToken } from "../../../services/notificationService";
import { fetchFlaggingKeywords } from "../../../services/cmsService";

export const POST_CATEGORY = "POST_CATEGORY";
export const USER = "USER";
export const UPDATE = "UPDATE";
export const FROM = "FROM";
export const ALL_CLEAR = "ALL_CLEAR";
export const FLAGGED_KEYWORDS = "FLAGGED_KEYWORDS";
export const IS_MODERATOR = "IS_MODERATOR";
export const MODERATOR_PERMISSIONS = "MODERATOR_PERMISSIONS";
export const TOP_CREATORS = "TOP_CREATORS";
export const SUGGESTIONS = "SUGGESTIONS";
export const IS_FCM_TOKEN_AVAILABLE = "IS_FCM_TOKEN_AVAILABLE";

export const UserAction = (dispatch) => {
  return async () => {
    try {
      const response = await getUser();
      if (response?.data?.user[0]) {
        const user = response.data.user[0];
        dispatch(SetUserAction(user));
        if (
          !!user.mux_live_brodcust &&
          !!user.mux_jwt &&
          !!user.mux_space_id &&
          !!user.auth_token
        ) {
          dispatch(SetIsUserLiveAction(true));
          if (user.auth_token === window.localStorage.getItem("token")) {
            dispatch(SetIsUserLiveFromThisSessionAction(true));
          } else {
            dispatch(SetIsUserLiveFromThisSessionAction(false));
          }
        } else {
          dispatch(SetIsUserLiveAction(false));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const FlaggingKeywordsAction = (dispatch) => {
  return async () => {
    try {
      const response = await fetchFlaggingKeywords();
      if (response.data.statusCode === 200) {
        const keywords = response.data?.keyword?.map((keyword) =>
          keyword.flagging_keyword.toLowerCase()
        );
        dispatch(SetFlaggingKeywordsAction(keywords));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const TopCreatorsAction = (dispatch) => {
  return async () => {
    const data = new FormData();
    data.append("isTopCreator", "1");

    try {
      const response = await fetchUsers(data);
      if (response.data.statusCode === 200) {
        dispatch(SetTopCreators(response.data.users));
      }
    } catch {
      //Empty catch block
    }
  };
};

export const SuggestionsAction = (dispatch) => {
  return async () => {
    try {
      const response = await fetchSuggestedUsers();
      if (response.data.statusCode === 200) {
        dispatch(SetSuggestions(response.data.data));
      }
    } catch (err) {
      console.log("SUGGESTIONS_ACTION_ERROR", err);
    }
  };
};

export const FetchFcmStatusAction = (dispatch) => {
  return async () => {
    try {
      const response = await checkFcmToken();
      if (response.status === 200) {
        dispatch(SetFcmTokenStatusAction(response.data.fcm_token_exists));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const SetUserAction = (value) => {
  return { type: USER, value };
};

export const SetTopCreators = (value) => {
  return { type: TOP_CREATORS, value };
};

export const SetSuggestions = (value) => {
  return { type: SUGGESTIONS, value };
};

export const SetFcmTokenStatusAction = (value) => {
  return { type: IS_FCM_TOKEN_AVAILABLE, value };
};

export const SetFlaggingKeywordsAction = (value) => {
  return { type: FLAGGED_KEYWORDS, value };
};
