import React, { useEffect, useState } from "react";
import TaggingDropdown from "./TaggingDropdown";
import { Input } from "reactstrap";
import axios from "axios";
import { userFuzzySearch } from "../services/postService";

let signal;

const TaggingInput = ({
  isPosting = false,
  value,
  setValueInParent,
  setTaggedUsersInParent,
  type,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);
  const [searchUsersQuery, setSearchUsersQuery] = useState(null);
  const [description, setDescription] = useState(value ? value : "");
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    let debounce = null;
    debounce = setTimeout(() => {
      getListOfUsers();
      debounce = null;
    }, 300);

    return () => {
      if (debounce) {
        clearTimeout(debounce);
      }
    };
  }, [searchUsersQuery]); // eslint-disable-line

  useEffect(() => {
    setValueInParent(description);
  }, [description]); // eslint-disable-line

  useEffect(() => {
    setTaggedUsersInParent(taggedUsers);
  }, [taggedUsers]); // eslint-disable-line

  useEffect(() => {
    if (value === "") {
      setDescription("");
      setTaggedUsers([]);
    }
  }, [value]);

  const getListOfUsers = async () => {
    const SIGNAL_CANCEL_MESSAGE = "signal_cancelled";
    if (signal) {
      signal.cancel(SIGNAL_CANCEL_MESSAGE);
    }
    signal = axios.CancelToken.source();

    try {
      const response = await userFuzzySearch(
        { search: searchUsersQuery, isModel: false },
        {
          cancelToken: signal.token,
        }
      );
      signal = undefined;
      if (response?.data?.data.length > 0) {
        setSuggestions(response.data.data);
        // setIsSuggestionsVisible(true);
        setIsLoading(false);
      } else {
        setSuggestions([]);
        setIsSuggestionsVisible(false);
        setIsLoading(false);
      }
    } catch (err) {
      // setIsSuggestionsVisible(false);
      // setIsLoading(false);
      console.log(err.message);
    }
  };

  const descriptionChangeHandler = (e) => {
    if (e.target.value.match(/@$/)) {
      setIsSuggestionsVisible(true);
      setSearchUsersQuery("");
    } else if (isSuggestionsVisible) {
      const lastPos = e.target.value.lastIndexOf("@");
      const query = e.target.value.slice(lastPos + 1);
      setSearchUsersQuery(query);
    } else if (
      !isSuggestionsVisible &&
      e.target.value.lastIndexOf("@") !== -1 &&
      !e.target.value.slice(e.target.value.lastIndexOf("@") + 1).includes(" ")
    ) {
      const query = e.target.value.slice(e.target.value.lastIndexOf("@") + 1);
      setSearchUsersQuery(query);
      setIsSuggestionsVisible(true);
    }

    //to close suggestions list if we enter space or all the characters are deleted
    if (e.target.value === "" || e.target.value.slice(-1) === " ") {
      setIsSuggestionsVisible(false);
    }

    //belows ifs are for when the user does not click on the suggestions list
    //but you still want to tag

    //executed if last input is " "
    if (
      e.target.value.slice(-1) === " " &&
      e.target.value.lastIndexOf("@") !== -1 &&
      !e.target.value
        .slice(e.target.value.lastIndexOf("@") + 1, -1)
        .includes(" ")
    ) {
      checkIfTaggedValidUser(
        e.target.value.slice(e.target.value.lastIndexOf("@") + 1, -1)
      );
    }

    //executed if last input is "@" if there is no space between last "@" and current "@"
    if (
      e.target.value.slice(-1) === "@" &&
      e.target.value.slice(0, -1).lastIndexOf("@") !== -1 &&
      !e.target.value
        .slice(e.target.value.slice(0, -1).lastIndexOf("@") + 1, -1)
        .includes(" ")
    ) {
      checkIfTaggedValidUser(
        e.target.value.slice(
          e.target.value.slice(0, -1).lastIndexOf("@") + 1,
          -1
        )
      );
    }

    /* TO DO
      1. Wait for the api call to complete before posting (could delay calls by 1 sec)
      2. Register the tag even if there is no space
    */

    // if (
    //   e.target.value.lastIndexOf("@") !== -1 &&
    //   !e.target.value.slice(e.target.value.lastIndexOf("@") + 1).includes(" ")
    // ) {
    //   if (debounceRef.current) {
    //     clearTimeout(debounceRef.current);
    //   }

    //   const query = e.target.value.slice(e.target.value.lastIndexOf("@") + 1);
    //   debounceRef.current = setTimeout(() => {
    //     checkIfTaggedValidUser(query);
    //     debounceRef.current = null;
    //   }, 300);
    // }

    setDescription(e.target.value);
  };

  const checkIfTaggedValidUser = async (query) => {
    try {
      const response = await userFuzzySearch({ search: query, isModel: false });
      if (response.data.data.length > 0) {
        const user = response.data.data.find(
          (user) => user.nickName === query || user.username === query
        );
        if (user) {
          const isUserAlreadyTagged = taggedUsers.some(
            (taggedUser) => taggedUser.id === user.id
          );
          if (!isUserAlreadyTagged) {
            setTaggedUsers([
              ...taggedUsers,
              { id: user.id, username: user.username },
            ]);
          }
        }
      }
    } catch (err) {
      console.log("Error in checkIfTaggedValidUser :", err);
    }
  };

  const hideDropdown = () => {
    setIsSuggestionsVisible(false);
    setSearchUsersQuery(null);
    setSuggestions(null);
  };

  const addTaggedUser = (taggedUser) => {
    const lastPos = description.lastIndexOf("@");

    const isUserAlreadyTagged = taggedUsers.some(
      (user) => user.id === taggedUser.id
    );

    if (isUserAlreadyTagged) {
      setDescription(
        `${description.slice(0, lastPos + 1)}${taggedUser.username} `
      );
    } else {
      setDescription(
        `${description.slice(0, lastPos + 1)}${taggedUser.username} `
      );
      setTaggedUsers([...taggedUsers, taggedUser]);
    }
  };

  return (
    <div className="form-label-group mt-2 mb-0">
      {!type ? (
        <Input
          type="textarea"
          name="text"
          maxLength="250"
          rows="4"
          placeholder="add something about you..."
          style={{ resize: "none" }}
          onChange={(e) => descriptionChangeHandler(e)}
          value={description}
          disabled={isPosting}
        />
      ) : type === "comment" ? (
        <Input
          type="textarea"
          rows="3"
          maxLength="250"
          placeholder="Add Comment"
          id="add-comment"
          style={{ resize: "none" }}
          value={description}
          onChange={(e) => descriptionChangeHandler(e)}
        />
      ) : type === "reply" ? (
        <Input
          type="textarea"
          rows="3"
          maxLength="250"
          placeholder="Add Reply"
          id="add-comment"
          style={{ resize: "none" }}
          value={description}
          onChange={(e) => descriptionChangeHandler(e)}
        />
      ) : null}
      {isSuggestionsVisible && (
        <TaggingDropdown
          users={suggestions}
          hideDropdown={hideDropdown}
          addTaggedUser={addTaggedUser}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default TaggingInput;
