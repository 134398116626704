import ServiceClient from "./serviceClientConfig";

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_APIS,
});

export const updateNotificationCount = (payload) => {
  return serviceClient.post("auth/notification/updateStatusAll", payload);
};

export const fetchNotifications = (payload) => {
  return serviceClient.post("auth/notification", payload);
};

export const storeFcmToken = (payload) => {
  return serviceClient.post("notification/users/fcm-token", payload);
};

export const checkFcmToken = () => {
  return serviceClient.get("notification/users/check-fcm-token");
};

export const fetchNotificationPreferences = () => {
  return serviceClient.get("user/notification-preferences");
};

export const updateNotificationPreferences = (payload, config) => {
  return serviceClient.post("user/notification-preferences", payload, config);
};
