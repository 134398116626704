import ServiceClient from "./serviceClientConfig";

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_APIS,
});

export const createPost = (payload, config) => {
  return serviceClient.post("post/post-create", payload, config);
};

export const fetchUserPosts = (payload, config) => {
  let url = "post/all-user-posts";

  if (payload.status) {
    url += `?status=${payload.status}`;
  }

  return serviceClient.post(url, { user_id: payload.userId }, config);
};

export const fetchPosts = () => {
  return serviceClient.post("post/all-users-posts");
};

export const fetchPublicPost = (payload) => {
  return serviceClient.get(
    `get-feature-fans-post-by-url?url_code=${payload.id}`
  );
};

export const fetchPublicPosts = (payload) => {
  return serviceClient.get(
    `auth/profile/posts?user_id=${payload.id}&no_of_records=${payload.count}`
  );
};

export const fetchPlatformPosts = (payload) => {
  return serviceClient.get(`feature-fans-posts?page_no=${payload.page}`);
};

export const fetchPost = (payload) => {
  return serviceClient.post("post/get-post-by-url", payload);
};

export const updatePost = (payload, urlParam) => {
  return serviceClient.post(`post/post_update/${urlParam.id}`, payload);
};

export const deletePost = (payload) => {
  return serviceClient.post("post/post-delete", payload);
};

export const reportPost = (payload, urlParam) => {
  return serviceClient.post(`post/post_report/${urlParam.id}`, payload);
};

export const buyPost = (payload) => {
  return serviceClient.post("post/unlocked", payload);
};

export const archivePost = (payload) => {
  return serviceClient.post("post/post-archived", payload);
};

export const showSensitivePost = (payload) => {
  return serviceClient.post("post/sensitive/content/show", payload);
};

export const appealPost = (payload, urlParam) => {
  return serviceClient.post(`post/appeal/${urlParam.id}`, payload);
};

export const addComment = (payload) => {
  return serviceClient.post("post/comment-create", payload);
};

export const deleteComment = (payload) => {
  return serviceClient.post(`post/comment-delete/${payload.id}`);
};

export const addSubComment = (payload) => {
  return serviceClient.post("post/sub-comment-create", payload);
};

export const deleteSubComment = (payload) => {
  return serviceClient.post(`post/sub-comment-delete/${payload.id}`);
};

export const likePost = (payload, config) => {
  return serviceClient.post("post/like_create_delete", payload, config);
};

export const likeComment = (payload, config) => {
  return serviceClient.post("comments/like", payload, config);
};

export const fetchReleaseForm = () => {
  return serviceClient.get("post/get-release-form-doc");
};

export const fetchCoPerformerReleaseForm = (payload) => {
  return serviceClient.post("post/release-for-with-co-uploader", payload);
};

export const signReleaseForm = (payload) => {
  return serviceClient.post("post/accept/agreement", payload);
};

export const userFuzzySearch = (payload, config) => {
  let url = "post/search/tagged-users";

  url += `?search=${payload.search ?? ""}`;
  url += `&isModel=${payload.isModel}`;

  return serviceClient.get(url, config);
};

export const sendSignReminder = (payload) => {
  return serviceClient.post("post/send-post-reminder", payload);
};
