import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
  Dropdown,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Settings, Power, User, Bell } from "react-feather";
import { Link } from "react-router-dom";
import Avatar from "../../../views/pages/AvatarComponent";
import { connect } from "react-redux";
import moment from "moment";
import VerificationModal from "../../../components/modals/VerificationModal";
import {
  GetNotificationsAction,
  SetNotificationCountAction,
} from "../../../redux/actions/notifications/notificationsActions";
import IconClose from "../../../assets/img/icon-cross.svg";
import logo from "../../../assets/img/logo/logo.png";
import { IKImage } from "imagekitio-react";
import { getUser, logout } from "../../../services/authService";
import { updateNotificationCount } from "../../../services/notificationService";
import notificationRedirection from "../../../utils/notificationRedirection";
import { bellNotificationTypeToNameMap } from "../../../utils/utils";

class NavbarUser extends React.PureComponent {
  state = {
    dropdown: false,
    isVerificationModalOpen: false,
    isLogoutAlertModalOpen: false,
    isLoggingOut: false,
  };

  readNotifications = () => {
    let data = new FormData();
    data.append("user_id", this.props.user.id);

    updateNotificationCount(data);
    this.props.getNotifications();
  };

  logoutHandler = async () => {
    try {
      this.setState({ isLoggingOut: true });
      if (this.props.isUserLive) {
        const isSameSession = await this.isUserLiveFromThisSession();

        if (isSameSession) {
          this.setState({ isLogoutAlertModalOpen: true });
          return;
        }
      }

      await logout();
      window.localStorage.clear();
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_BASE_URL;
      }, 300);
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isLoggingOut: false });
    }
  };

  isUserLiveFromThisSession = async () => {
    try {
      const response = await getUser();
      if (response.data?.user[0]) {
        const user = response.data.user[0];
        const token = window.localStorage.getItem("token");
        return !!user.mux_jwt && user.auth_token === token;
      }
    } catch (err) {
      console.log(err);
    }
  };

  notificationClickHandler = (notification) => {
    return () => {
      notificationRedirection(notification, this.props.props.props.history);
      this.setState({ dropdown: false });
    };
  };

  closeVerificationModal = () => {
    this.setState({ isVerificationModalOpen: false });
  };

  closeLogoutAlertModal = () => {
    this.setState({ isLogoutAlertModalOpen: false });
  };

  toggleDropdown = () => {
    if (!this.state.dropdown) {
      this.readNotifications();
    }
    this.setState({ dropdown: !this.state.dropdown });
  };

  componentDidUpdate(_, prevState) {
    if (prevState.dropdown && !this.state.dropdown) {
      this.props.setNotificationCount(0);
    }
  }

  render() {
    return (
      <>
        <ul className="nav navbar-nav navbar-nav-user float-right customNav justify-content-end">
          <li className="d-flex nav-stats">
            {this.props.user.isApprovedModel === 1 ? (
              <div>
                <div className="header-wallet-box">
                  <Link to="/settings/#/earnings">
                    <Badge pill className="badge-up" color="warning">
                      Earning:{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(this.props.user.balanceAmount)}
                    </Badge>
                  </Link>
                </div>
              </div>
            ) : null}
            <div>
              <div className="header-wallet-box">
                <Link to="/settings/#/wallet">
                  <Badge pill className="badge-up" color="primary">
                    Wallet:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(this.props.user.walletAmount)}
                  </Badge>
                </Link>
              </div>
            </div>
          </li>
          <Dropdown
            tag="li"
            className="dropdown-notification nav-item"
            isOpen={this.state.dropdown}
            toggle={this.toggleDropdown}
          >
            <DropdownToggle tag="a" className="nav-link nav-link-label">
              <Bell size={21} />
              <Badge
                pill
                color={this.props.theme === "Light" ? "primary" : "warning"}
                className="badge-up"
                style={{ width: "max-content", padding: "5px" }}
              >
                {this.props.notificationCount}
              </Badge>
            </DropdownToggle>
            <DropdownMenu
              tag="ul"
              right
              className="dropdown-menu-media Notifications"
            >
              <li className="dropdown-menu-header">
                <div className="dropdown-header mt-0">
                  <h3 className="text-white">
                    {this.props.notificationCount === 0
                      ? "No"
                      : this.props.notificationCount}{" "}
                    {this.props.notificationCount === 1
                      ? "New Notification"
                      : "New Notifications"}
                  </h3>
                </div>
              </li>
              {this.state.dropdown && (
                <PerfectScrollbar
                  className="media-list overflow-hidden position-relative"
                  options={{
                    wheelPropagation: false,
                  }}
                >
                  {this.props.isLoadingNotification ? (
                    <div className="text-center my-2">
                      <Spinner color="warning" className="m-auto" />
                    </div>
                  ) : (
                    this.props.notifications
                      .sort((a, b) => b.id - a.id)
                      .slice(0, 10)
                      .map((item, index) => {
                        return (
                          <div
                            className="d-flex justify-content-between"
                            key={index}
                            onClick={this.notificationClickHandler(item)}
                          >
                            <Media className="d-flex align-items-start">
                              <Media body>
                                <Media
                                  style={{ textTransform: "capitalize" }}
                                  heading
                                  className={
                                    this.props.theme === "light"
                                      ? "primary media-heading"
                                      : "warning media-heading"
                                  }
                                  tag="h6"
                                >
                                  {bellNotificationTypeToNameMap[
                                    item.notification_type
                                  ] ?? item.notification_type}
                                </Media>
                                <p className="notification-text">
                                  {item.message}
                                </p>
                              </Media>
                              <small>{moment(item.created_at).fromNow()}</small>
                            </Media>
                          </div>
                        );
                      })
                  )}
                </PerfectScrollbar>
              )}
              <li className="dropdown-menu-footer">
                <DropdownItem
                  tag="a"
                  className={`p-1 text-center ${
                    this.props.theme === "dark" ? "DarkHover2" : ""
                  }`}
                  onClick={() => {
                    this.props.props.props.history.push("/Notifications");
                  }}
                >
                  <span className="align-middle">Read all notifications</span>
                </DropdownItem>
              </li>
            </DropdownMenu>
          </Dropdown>
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
              <div className="user-nav d-sm-flex d-none">
                <span
                  className="user-name text-bold-600"
                  style={{ textTransform: "capitalize" }}
                >{`${
                  this.props.user?.nickName || this.props.user.username
                }`}</span>
                <span className="user-status">
                  {this.props.user.isApprovedModel
                    ? this.props.user.applied_as ?? "Model"
                    : "Fan"}
                </span>
              </div>

              <span data-tour="user">
                {this.props.userImg ? (
                  <IKImage
                    path={this.props.userImg.split(".com/")[1]}
                    transformation={[
                      {
                        width: 60,
                        height: 60,
                      },
                    ]}
                    width="40"
                    height="40"
                    className="round"
                  />
                ) : (
                  <Avatar
                    content={
                      this.props.user?.nickName?.charAt(0) ??
                      this.props.user?.username?.charAt(0)
                    }
                  />
                )}
              </span>
            </DropdownToggle>
            <DropdownMenu right className="userCustomDropdown">
              <Link to="/profile">
                <DropdownItem style={{ width: "100%", color: "#800080" }}>
                  <User
                    style={{
                      color:
                        this.props.theme === "Light" ? "#800080" : "#d3af37",
                    }}
                    size={14}
                    className="mr-50"
                  />
                  <span className="align-middle">Profile</span>
                </DropdownItem>
              </Link>

              <DropdownItem divider />
              <Link to="/settings">
                <DropdownItem style={{ width: "100%", color: "#800080" }}>
                  <Settings
                    style={{
                      color:
                        this.props.theme === "Light" ? "#800080" : "#d3af37",
                    }}
                    size={14}
                    className="mr-50"
                  />
                  <span className="align-middle">Settings</span>
                </DropdownItem>
              </Link>
              <DropdownItem divider />
              <DropdownItem
                onClick={this.logoutHandler}
                style={{ width: "100%", color: "#800080" }}
              >
                <Power
                  style={{
                    color: this.props.theme === "Light" ? "#800080" : "#d3af37",
                  }}
                  size={14}
                  className="mr-50"
                />
                <span className="align-middle">Log Out</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ul>

        <VerificationModal
          isModalOpen={this.state.isVerificationModalOpen}
          closeModal={this.closeVerificationModal}
        />

        <Modal
          isOpen={this.state.isLogoutAlertModalOpen}
          centered
          toggle={this.closeLogoutAlertModal}
          className="model-suggestion-modal"
        >
          <div className="modal-close-btn" onClick={this.closeLogoutAlertModal}>
            <img src={IconClose} alt="close-icon" />
          </div>
          <ModalBody>
            <h3 className="text-center">
              Sorry, unable to log out since you have an active live stream.
              Click{" "}
              <Link
                to="/streaming"
                style={{ color: "#d3af37", textDecoration: "underline" }}
                onClick={this.closeLogoutAlertModal}
              >
                here
              </Link>{" "}
              to access the live stream to end it before logging out.
            </h3>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.isLoggingOut} centered>
          <img className="fallback-logo logo-anim" src={logo} alt="logo" />
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.app.user,
    notifications: state.notifications.notifications,
    isLoadingNotification: state.notifications.isLoading,
    notificationCount: state.notifications.notificationCount,
    theme: state.customizer.theme,
    isUserLive: state.live.isUserLive,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    Notifications: (value) => dispatch({ type: "NOTIFICATION", value: value }),
    updateHandler: (value) => dispatch({ type: "UPDATE", value: value }),
    ThemeHandler: (mode) => dispatch({ type: "CHANGE_MODE", mode: mode }),
    getNotifications: GetNotificationsAction(dispatch),
    setNotificationCount: (value) =>
      dispatch(SetNotificationCountAction(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser);
