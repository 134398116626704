import React from "react";
import { Navbar } from "reactstrap";
import classnames from "classnames";
import NavbarUser from "./NavbarUser";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import {
  UserAction,
  TopCreatorsAction,
  SuggestionsAction,
  FetchFcmStatusAction,
  FlaggingKeywordsAction,
} from "../../../redux/actions/app/appActions";
import { ChatUsersAction } from "../../../redux/actions/messaging/messagingActions";
import NewPostModal from "../../../components/modals/NewPostModal";
import { GetNotificationsAction } from "../../../redux/actions/notifications/notificationsActions";
import { GetLiveModelsAction } from "../../../redux/actions/liveActions/liveActions";
import withPubnub from "../../../components/containers/withPubnub";
import { purchaseSignalType } from "../../../utils/utils";

const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
const navbarTypes = ["floating", "static", "sticky", "hidden"];
const axios = require("axios");

class ThemeNavbar extends React.Component {
  state = {
    modal: false,
    postCat: "Feed",
    didSubscribeToPubnubUsers: false,
  };

  signal = axios.CancelToken.source();
  pubnubListener = {
    message: (message) => {
      if (message.message?.type === "follow") {
        const userId = +this.props.user.id;
        const chatUserId = message.message?.user.id;
        if (chatUserId) {
          const onlineChannel = `online${userId + +chatUserId}${
            userId * +chatUserId
          }`;
          this.props.pubnub.subscribe({
            channels: [onlineChannel],
            withPresence: true,
          });
          this.props.pubnub.subscribe({
            channels: [`chat${userId + +chatUserId}${userId * +chatUserId}`],
          });
          this.props.setOnlineChannelsAction({ [onlineChannel]: true });
        }
        this.props.getChatUsers();
      } else {
        if (
          +message.publisher !== this.props.user.id &&
          window.location.pathname.split("/").at(-1).toLowerCase() !== "chat" &&
          !message.channel.startsWith("Live")
        ) {
          this.props.appendMessageCount(1);
        }
      }
    },
    signal: (s) => {
      const splitMessage = s.message.split(".");

      if (
        splitMessage[0] === purchaseSignalType &&
        +splitMessage[1] === this.props.user.id
      ) {
        this.props.getUser();
      }
    },
    presence: (p) => {
      if (p.action === "join" && +p.uuid !== this.props.user.id) {
        this.props.setOnlineChannelsAction({ [p.channel]: true });
      }

      if (p.action === "leave" && +p.uuid !== this.props.user.id) {
        this.props.setOnlineChannelsAction({ [p.channel]: false });
      }
    },
  };

  storageListener = (e) => {
    if (e.key === "user_id") {
      window.location.reload();
    }
  };

  getUnreadMessages = async () => {
    if (window.location.pathname.split("/").at(-1).toLowerCase() === "chat") {
      return;
    }

    let messageCheckTimestamp = null;
    await this.props.pubnub.objects
      .getChannelMetadata({
        channel: `user.${this.props.user.id}`,
      })
      .then(async (res) => {
        if (res.status === 200) {
          messageCheckTimestamp = res.data?.custom?.lastMessageCheckTime;

          const timestamps = {};
          this.props.allUsers.forEach((user) => {
            const myId = this.props.user.id;
            const channel = `chat${+myId + +user.user.id}${
              +myId * +user.user.id
            }`;
            if (messageCheckTimestamp) {
              timestamps[channel] = messageCheckTimestamp;
            } else {
              const timetoken = new Date().getTime() + "0000";
              timestamps[channel] = timetoken;
            }
          });

          const channels = Object.keys(timestamps);
          const channelTimetokens = Object.values(timestamps);
          const LIMIT = 100;
          const noOfIterations = Math.ceil(channels.length / LIMIT);
          let messageCount = 0;

          for (let i = 0; i < noOfIterations; i++) {
            try {
              const response = await this.props.pubnub.messageCounts({
                channels: channels.slice(i * LIMIT, i * LIMIT + LIMIT),
                channelTimetokens: channelTimetokens.slice(
                  i * LIMIT,
                  i * LIMIT + LIMIT
                ),
              });

              messageCount += Object.keys(response.channels).reduce(
                (acc, cur) => acc + response.channels[cur],
                0
              );
            } catch (err) {
              console.log(err);
            }
          }

          this.props.setMessageCount(messageCount);
        } else {
          this.props.setMessageCount(0);
        }
      })
      .catch((err) => {
        //Catch errors when the respective object is not found
        console.log(err.status);
        if (err.status?.errorData?.status === 404) {
          const messageCheckTimestamp = new Date().getTime() + "0000";
          this.props.pubnub.objects
            .setChannelMetadata({
              channel: `user.${this.props.user.id}`,
              data: {
                name: "Last read",
                description: "Used to store last read timestamps of a user",
                custom: { lastMessageCheckTime: messageCheckTimestamp },
              },
            })
            .then(() => {
              this.getUnreadMessages();
            });
        } else {
          this.props.setMessageCount(0);
        }
      });
  };

  subscribeUsersToPubnub = () => {
    if (!!this.props.chatUsersIds && this.props.chatUsersIds.length > 0) {
      const userId = +this.props.user.id;
      const me = this;
      const onlineChannels = [];
      const chatChannels = [];

      this.props.chatUsersIds.forEach((chatUserId) => {
        const onlineChannel = `online${userId + +chatUserId}${
          userId * +chatUserId
        }`;
        onlineChannels.push(onlineChannel);

        const chatChannel = `chat${userId + +chatUserId}${
          userId * +chatUserId
        }`;
        chatChannels.push(chatChannel);
      });

      const CHANNEL_GROUP_LIMIT = 1000;
      const noOfChannelGroups = Math.ceil(
        this.props.chatUsersIds.length / CHANNEL_GROUP_LIMIT
      );

      //client can only subscribe to 10 channel groups
      for (let i = 0; i < noOfChannelGroups; i++) {
        this.props.pubnub.channelGroups.addChannels(
          {
            channels: onlineChannels.slice(
              i * CHANNEL_GROUP_LIMIT,
              i * CHANNEL_GROUP_LIMIT + CHANNEL_GROUP_LIMIT
            ),
            channelGroup: `cg_${userId}_${i + 1}-presence`,
          },
          function (status) {
            // console.log(status);
            me.props.pubnub.subscribe({
              channelGroups: [`cg_${userId}_${i + 1}-presence`],
              withPresence: true,
            });
            me.getOnlineUsersInChannelGroup(`cg_${userId}_${i + 1}-presence`);
          }
        );

        this.props.pubnub.channelGroups.addChannels(
          {
            channels: chatChannels.slice(
              i * CHANNEL_GROUP_LIMIT,
              i * CHANNEL_GROUP_LIMIT + CHANNEL_GROUP_LIMIT
            ),
            channelGroup: `cg_${userId}_${i + 1}`,
          },
          function (status) {
            // console.log(status);
            me.props.pubnub.subscribe({
              channelGroups: [`cg_${userId}_${i + 1}`],
              withPresence: true,
            });
          }
        );
      }
    }
  };

  getOnlineUsersInChannelGroup = (channels) => {
    const me = this;
    const userId = +this.props.user.id;

    this.props.pubnub.hereNow(
      {
        channelGroups: [channels],
      },
      function (status, response) {
        // console.log(response);
        if (!status.error) {
          Object.keys(response.channels).forEach((channel) => {
            if (
              response.channels[channel].occupancy === 1 &&
              +response.channels[channel].occupants[0].uuid !== userId
            ) {
              me.props.setOnlineChannelsAction({ [channel]: true });
            } else if (response.channels[channel].occupancy > 1) {
              me.props.setOnlineChannelsAction({ [channel]: true });
            }
          });
        }
      }
    );
  };

  sendBeacon = async () => {
    const formData = new FormData();
    formData.append(
      "is_tab_visible",
      document.visibilityState === "visible" ? "1" : "0"
    );
    formData.append("user_id", window.localStorage.getItem("user_id"));

    navigator.sendBeacon(
      `${process.env.REACT_APP_APIS}mux/set_tab_visibility`,
      formData
    );

    if (
      document.visibilityState === "visible" &&
      window.location.href.toLowerCase().includes("/home")
    ) {
      this.props.getLiveModels();
    }
  };

  async componentDidMount() {
    window.addEventListener("storage", this.storageListener, false);

    this.props.getUser();
    this.props.getTopCreators();
    this.props.getSuggestions();
    this.props.getNotifications();
    this.props.fetchFcmStatus();
    this.props.getFlaggingKeywords();

    document.addEventListener("visibilitychange", this.sendBeacon);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.pubnub && !!this.props.pubnub) {
      this.props.pubnub.addListener(this.pubnubListener);
      await this.props.getChatUsers();
      this.props.pubnub.subscribe({
        channels: [`user.${this.props.user.id}`],
      });
    }

    if (
      this.props.chatUsersIds !== prevProps.chatUsersIds &&
      !this.state.didSubscribeToPubnubUsers
    ) {
      this.subscribeUsersToPubnub();
      this.setState({ didSubscribeToPubnubUsers: true });
    }

    if (
      prevProps.isFetchingUsers === true &&
      this.props.isFetchingUsers === false
    ) {
      this.getUnreadMessages();
    }
  }

  componentWillUnmount() {
    this.props.pubnub.removeListener(this.pubnubListener);
    window.removeEventListener("storage", this.storageListener, false);
    document.removeEventListener("visibilitychange", this.sendBeacon);
  }

  FeedHandler = () => {
    if (this.props.props.match.url === "/Home") {
      this.props.FeedHandler();
    } else {
      this.props.props.history.push("/Home");
      this.props.FeedHandler();
    }
  };

  PublicHandler = () => {
    if (this.props.props.match.url === "/Home") {
      this.props.PublicHandler();
    } else {
      this.props.props.history.push("/Home");
      this.props.PublicHandler();
    }
  };

  PrivateHandler = () => {
    if (this.props.props.match.url === "/Home") {
      this.props.PrivateHandler();
    } else {
      this.props.props.history.push("/Home");
      this.props.PrivateHandler();
    }
  };

  closeNewPostModal = () => {
    this.setState({ modal: false });
  };

  render() {
    return (
      <>
        <div className="content-overlay" />
        <div className="header-navbar-shadow" />
        <Navbar
          className={classnames(
            "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
            {
              "navbar-light":
                this.props.navbarColor === "default" ||
                !colorsArr.includes(this.props.navbarColor),
              "navbar-dark": colorsArr.includes(this.props.navbarColor),
              "bg-primary":
                this.props.navbarColor === "primary" &&
                this.props.navbarType !== "static",
              "bg-danger":
                this.props.navbarColor === "danger" &&
                this.props.navbarType !== "static",
              "bg-success":
                this.props.navbarColor === "success" &&
                this.props.navbarType !== "static",
              "bg-info":
                this.props.navbarColor === "info" &&
                this.props.navbarType !== "static",
              "bg-warning":
                this.props.navbarColor === "warning" &&
                this.props.navbarType !== "static",
              "bg-dark":
                this.props.navbarColor === "dark" &&
                this.props.navbarType !== "static",
              "d-none":
                this.props.navbarType === "hidden" && !this.props.horizontal,
              "floating-nav":
                (this.props.navbarType === "floating" &&
                  !this.props.horizontal) ||
                (!navbarTypes.includes(this.props.navbarType) &&
                  !this.props.horizontal),
              "navbar-static-top":
                this.props.navbarType === "static" && !this.props.horizontal,
              "fixed-top":
                this.props.navbarType === "sticky" || this.props.horizontal,
              scrolling: this.props.horizontal && this.props.scrolling,
            }
          )}
        >
          <div className="navbar-wrapper">
            <div
              className="navbar-container content"
              style={{
                boxShadow: "0px 10px 20px #3d3d3d16",
                minHeight: "62.29px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className="navbar-collapse d-flex justify-content-between align-items-center"
                id="navbar-mobile"
              >
                <Icon.Menu
                  className="ficon"
                  style={{
                    color: this.props.theme === "dark" ? "#fff" : "#000",
                  }}
                  onClick={this.props.sidebarVisibility}
                />
                <div className="postCat">
                  <span
                    className={`${
                      this.props.postCat === "Feed" &&
                      (this.props.theme === "light" ||
                        this.props.theme === "Light")
                        ? "postCatActive"
                        : ""
                    }${
                      this.props.postCat === "Feed" &&
                      this.props.theme === "dark"
                        ? "postCatActiveDark"
                        : ""
                    }`}
                    onClick={() => this.FeedHandler()}
                  >
                    Feed
                  </span>
                  <span
                    className={`${
                      this.props.postCat === "Public" &&
                      (this.props.theme === "light" ||
                        this.props.theme === "Light")
                        ? "postCatActive"
                        : ""
                    }${
                      this.props.postCat === "Public" &&
                      this.props.theme === "dark"
                        ? "postCatActiveDark"
                        : ""
                    }`}
                    onClick={() => this.PublicHandler()}
                  >
                    Premium
                  </span>
                  {this.props.user?.isApprovedModel === 1 && (
                    <span
                      className={`${
                        this.props.postCat === "Private" &&
                        (this.props.theme === "light" ||
                          this.props.theme === "Light")
                          ? "postCatActive"
                          : ""
                      }${
                        this.props.postCat === "Private" &&
                        this.props.theme === "dark"
                          ? "postCatActiveDark"
                          : ""
                      }`}
                      onClick={() => this.PrivateHandler()}
                    >
                      Archive
                    </span>
                  )}
                  {this.props.user?.isApprovedModel === 1 ? (
                    !this.props.isModerator ||
                    (this.props.isModerator &&
                      this.props.moderatorPermissions.writePost) ? (
                      <Icon.PlusCircle
                        style={{
                          cursor: "pointer",
                          color:
                            this.props.theme === "dark" ? "#d3af37" : "#800080",
                        }}
                        onClick={() => this.setState({ modal: true })}
                      />
                    ) : null
                  ) : null}
                  {this.props.user?.isApprovedModel === 1 &&
                    this.props.user?.is_go_live === 1 &&
                    !this.props.isModerator && (
                      <span
                        onClick={() => {
                          this.props.props.history.push("/streaming");
                        }}
                        style={{ marginLeft: "1.5rem" }}
                      >
                        <span className="live-icon-container">
                          <Icon.Radio
                            style={{
                              cursor: "pointer",
                              color:
                                this.props.theme === "dark"
                                  ? "#d3af37"
                                  : "#800080",
                            }}
                          />
                          {this.props.isUserLive ? (
                            <span className="live-indicator"></span>
                          ) : null}
                        </span>
                        <span
                          style={{
                            color:
                              this.props.theme === "dark"
                                ? "#d3af37"
                                : "#800080",
                            marginLeft: "5px",
                          }}
                        >
                          Go Live!
                        </span>
                      </span>
                    )}
                </div>
                {this.props.horizontal ? (
                  <div className="logo d-flex align-items-center">
                    <div className="brand-logo mr-50"></div>
                    <h2
                      className="mb-0"
                      style={{
                        color: this.props.theme === "dark" ? "#fff" : "#000",
                      }}
                    >
                      Feature
                    </h2>
                    <h2 className="mb-0" style={{ color: "#d3af37" }}>
                      Fans
                    </h2>
                  </div>
                ) : null}

                {/* {this.state.mainLoading && ( */}
                <NavbarUser
                  props={this.props}
                  handleAppOverlay={this.props.handleAppOverlay}
                  changeCurrentLang={this.props.changeCurrentLang}
                  user={this.props.user}
                  userImg={this.props.user.image}
                />
                {/* )} */}
              </div>
            </div>
          </div>
        </Navbar>

        {this.props?.props?.location?.pathname !== "/streaming" &&
        this.props.isUserLive &&
        this.props.isUserLiveFromThisSession ? (
          <div className="live-stream-alert">
            <p
              style={{
                color: this.props.theme === "Light" ? "#800180" : "#c9a737",
              }}
            >
              <Icon.AlertTriangle size={26} stroke="#ea3939" />
              {"    "}
              You are currently streaming live, please do not refresh your page
              or close your tab.
            </p>
          </div>
        ) : null}

        <NewPostModal
          isModalOpen={this.state.modal}
          closeModal={this.closeNewPostModal}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    postCat: state.app.postCat,
    user: state.app.user,
    isModerator: state.app.isModerator,
    moderatorPermissions: state.app.moderatorPermissions,
    theme: state.customizer.theme,
    chatUsersIds: state.messaging.chatUsersIds,
    allUsers: state.messaging.allUsers,
    isFetchingUsers: state.messaging.isFetchingUsers,
    isUserLive: state.live.isUserLive,
    isUserLiveFromThisSession: state.live.isUserLiveFromThisSession,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    FeedHandler: () => dispatch({ type: "POST_CATEGORY", value: "Feed" }),
    PublicHandler: () => dispatch({ type: "POST_CATEGORY", value: "Public" }),
    PrivateHandler: () => dispatch({ type: "POST_CATEGORY", value: "Private" }),
    UserHandler: (value) => dispatch({ type: "USER", value: value }),
    Notifications: (value) => dispatch({ type: "NOTIFICATION", value: value }),
    getUser: UserAction(dispatch),
    getChatUsers: ChatUsersAction(dispatch),
    setOnlineChannelsAction: (value) =>
      dispatch({ type: "ONLINE_CHANNELS", value }),
    appendMessageCount: (value) =>
      dispatch({ type: "APPEND_MESSAGE_COUNT", value }),
    setMessageCount: (value) => dispatch({ type: "SET_MESSAGE_COUNT", value }),
    getTopCreators: TopCreatorsAction(dispatch),
    getSuggestions: SuggestionsAction(dispatch),
    getNotifications: GetNotificationsAction(dispatch, true),
    getLiveModels: GetLiveModelsAction(dispatch),
    fetchFcmStatus: FetchFcmStatusAction(dispatch),
    getFlaggingKeywords: FlaggingKeywordsAction(dispatch),
  };
};
export default withPubnub(
  connect(mapStateToProps, mapDispatchToProps)(ThemeNavbar)
);
