import {
  CHAT_USERS_IDS,
  ONLINE_CHANNELS,
  SET_MESSAGE_COUNT,
  APPEND_MESSAGE_COUNT,
  ALL_USERS,
  FOLLOWERS,
  FOLLOWING,
  SUBSCRIBERS,
  SUBSCRIBING,
  IS_FETCHING_USERS,
} from "../../actions/messaging/messagingActions";

export const messagingInitialState = {
  chatUsersIds: null,
  onlineChannels: {},
  newMessageCount: 0,
  allUsers: [],
  followers: [],
  following: [],
  subscribers: [],
  subscribing: [],
  isFetchingUsers: false,
};

const messaging = (state = messagingInitialState, action) => {
  switch (action.type) {
    case CHAT_USERS_IDS:
      return {
        ...state,
        chatUsersIds: action.value,
      };
    case ALL_USERS:
      return {
        ...state,
        allUsers: action.value,
      };
    case FOLLOWERS:
      return {
        ...state,
        followers: action.value,
      };
    case FOLLOWING:
      return {
        ...state,
        following: action.value,
      };
    case SUBSCRIBERS:
      return {
        ...state,
        subscribers: action.value,
      };
    case SUBSCRIBING:
      return {
        ...state,
        subscribing: action.value,
      };
    case ONLINE_CHANNELS:
      return {
        ...state,
        onlineChannels: { ...state.onlineChannels, ...action.value },
      };
    case SET_MESSAGE_COUNT:
      return {
        ...state,
        newMessageCount: action.value,
      };
    case APPEND_MESSAGE_COUNT:
      return {
        ...state,
        newMessageCount: state.newMessageCount + action.value,
      };
    case IS_FETCHING_USERS:
      return {
        ...state,
        isFetchingUsers: action.value,
      };
    default:
      return state;
  }
};
export default messaging;
